<template>
  <div class="container">
    <div class="nf-tabs">
      <tab-nav :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav>
    </div>
    <div class="echarts-conent">
      <div class="echs-btnbox">
        <el-radio-group v-model="labelPosition" @change="radChange" size="small">
          <el-radio-button label="week">周</el-radio-button>
          <el-radio-button label="month">月</el-radio-button>
        </el-radio-group>
      </div>
      <div class="echs-main" v-if="echsData.length">
        <div id="reportEcharts"></div>
      </div>
      <div v-else class="noData" style="text-align: center;">
        <img src="@/assets/images/noData.png" alt="" />
      <p>暂无相关数据</p>
    </div>
    </div>

  </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
// import noData from '@/component/noData/index';
import TabNav from './component/tab-nav';

// import list from "./mock/index";

export default {
  components: {
    TabNav
  },
  created() {
  },
  data() {
    return {
      tabsNav: ['检测报告'],
      currentTabsNav: 0,
      labelPosition: 'week',
      echsData: [
          {value: 20, name: '棉花'},
          {value: 30, name: '玉米'},
          {value: 25, name: '小麦'}
      ]
    };
  },
  mounted(){
    this.requertList(1);
  },
  methods: {
    switchNav(data) {
      this.currentTabsNav = data;
    },
    radChange(val){
      if (val === 'month'){
        this.requertList(2);
      } else {
        this.requertList(1);
      }
      
      
    },
    async requertList(type){
      const api = this.$fetchApi.statistical.api;
      const data = await this.$fetchData.fetchPost({ type }, api, 'json');
      if (data.code === '200') {
        this.echsData = [];
        const _data = data.result;
        _data.ySum.forEach((item, index) => {
          const _echsJson = {};
          _echsJson.value = item;
          _echsJson.name = _data.xName[index];
          this.echsData.push(_echsJson);
        });
        
        if (this.echsData.length > 0){
          setTimeout(() => {
            this.setReportEcharts(this.echsData);
          }, 100);
        }
      }
      
    },
    /**设置echarts图 */
    setReportEcharts(data){
      const repEcharts=this.$echarts.init(document.getElementById("reportEcharts"));
      const option = this.reportOptions(data);
      repEcharts.setOption(option);
    },
    /**echarts图配置 */
    reportOptions(dataList){
      const option = {
                color: ['#C78FD5', '#3A64A9', '#E66565', '#5BBFFD', '#F8AF48', '#F8AF48', '#82DDDF', '#8378ea'],
                tooltip: {
                  trigger: 'item',
                  // formatter: "{a} <br/>{b} : {c} ({d}%)"
                  formatter: "{b} : {d}%"
                },
                toolbox: {
                  show: true
                },
                legend: {
                  type: "scroll",
                  orient: 'vertical',
                  right: '15%',
                  align: 'left',
                  top: 'middle',
                  itemWidth: 15,
                  textStyle: {
                    color: '#8C8C8C'
                  },
                  formatter(name){
                    return name.length>5?name.substr(0, 10)+"...":name;
                  },
                  height: 150
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: [100, 150],
                    label: {
                      normal: {
                          formatter: params => {
                              return ('{percent|'+ params.percent.toFixed(1) +'%}');
                          },
                          rich: {
                              color: '#333',
                              percent: {
                                  color: '#333',
                                  fontSize: 12,
                                  padding: [0, 5, 0, 0]
                              }
                          }
                      }
                  },
                    data: dataList
                  }
                ]
          };
        return option;
    }
  }
};
</script>

<style lang="less" scoped>
.tab-content {
  max-width: 1200px;
  margin: 0 auto;
}

.breadcrumb {
  border: none;
  margin-bottom: 30px;
}

.form-row {
  width: 105px;

  &-date {
    width: 250px;
  }
}

.filter-form {
  padding: 25px 0 0 20px;
}

.el-form-item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.nf-tag {
  display: inline-block;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;

  &-jinji {
    background: #f56c6c;
  }

  &-ji {
    background: #e6a23c;
  }

  &-yiban {
    background: #909399;
  }
}

.table-cont-title {
  margin-left: 10px;
  cursor: pointer;
}

/deep/ .red {
  color: red !important;
}
.container{
  .echarts-conent{
    .echs-btnbox{
      padding: 20px 30px 15px 200px;
    }
    .echs-main{
      #reportEcharts{
        width: 100%;
        height: 350px;
      }
    }
  }
}
</style>
